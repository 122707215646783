<template>
    <div style="position: relative;">
        <div class="w3-bar w3-theme-d1 de-btn w3-tiny" style="margin-bottom: 0px; padding: 3px;">
            <a @click="showMenu=!showMenu" title="Add" class="w3-bar-item w3-circle de-btn de-blue w3-green w3-small w3-hide-medium w3-hide-large"><i class="fa fa-bars w3-medium"></i></a>

            <a title="Add" style="margin-right:3px;" v-if="statusAdd" class="w3-bar-item w3-btn de-btn de-blue w3-yellow w3-tiny w3-hide-small" @click="showAdd()">
                <i class="fa fa-plus w3-medium"></i><span class="w3-hide-small"> Add</span>
            </a>        
            <a title="Refresh" style="margin-right:3px;" v-if="statusRefresh" class="w3-bar-item w3-btn de-btn de-blue w3-green w3-tiny w3-hide-small" @click="showRefresh()"><i class="fa fa-refresh w3-medium"></i><span class="w3-hide-small"> Refresh</span></a>
            <a title="Export" style="margin-right:3px;" v-if="statusExport" class="w3-bar-item w3-btn de-btn de-blue w3-deep-orange w3-tiny w3-hide-small" @click="generate"><i class="fa fa-file-excel-o w3-medium"></i><span class="w3-hide-small"> Export</span></a>
            <a title="Print" style="margin-right:3px;" v-if="statusPrint" class="w3-bar-item w3-btn de-btn de-blue w3-tiny w3-hide-small" @click="showPrint()"><i class="fa fa-print w3-medium" ></i><span class="w3-hide-small"> Print</span></a>
            <slot name="button"></slot>
            <div v-if="statusFilter" class="w3-bar-item w3-btn de-btn de-blue w3-tiny" style="margin: 0 3px 0 0;padding: 0px;">
                <select v-on:change="setTypeQuery(filters.findQuery[0])" v-model="filters.findQuery[0]['field']" class="w3-select w3-tiny" style="width:100px;height: 33px;">
                    <option value="">.: Cari :.</option>
                    <option  v-for="fl in setFilterField(fieldFind)" :key="fl.kunci" :value="fl.kunci">{{fl.name}}</option>
                </select>
            </div>
            <div v-if="statusFilter" class="w3-bar-item w3-btn de-btn de-blue w3-small" style="margin: 0 ;margin: 0 3px 0 0; padding:0px">
                <input v-on:keyup.enter="prosesFilter" v-if="filters.findQuery[0].type=='text'" type="text" class="w3-input w3-small" v-model="filters.findQuery[0].fieldValue" style="max-width:200px;display: inline-block!important; height: 33px;" placeholder="Masukkan kata kunci ...">

                <select  v-on:change="prosesFilter" v-if="filters.findQuery[0].type=='select'" v-model="filters.findQuery[0].fieldValue" class="w3-select w3-small" style="max-width:200px;display: inline-block!important; margin-right:0px;height: 33px;">
                    <option  v-for="fl in filters.findQuery[0].valueFind" :key="fl.key" :value="fl.key">{{fl.label}}</option>
                </select>

                <div class="w3-text-black" v-if="filters.findQuery[0].type=='date'" style="position: relative;max-width:200px;display: inline-block!important; margin-right:5px;">
                    <input v-on:click="filters.findQuery[0].showdate=true" @change="prosesFilter" type="date" class="w3-input w3-small" placeholder="Tanggal" v-model="filters.findQuery[0].fieldValue" style="height: 33px;">
                </div>
            </div>
            <a title="Filter" style="margin-right:3px;" v-if="statusFilterAdvance" class="w3-bar-item w3-btn de-btn de-blue w3-tiny" @click="showFilterAdvance()"><i class="fa fa-filter w3-medium"></i><span class="w3-hide-small"> Advance Filter</span></a>
            
            <div v-if="help!=''" class="w3-bar-item w3-right" style="margin: 0;padding: 2px 0 2px 0;">
                <button title="Bantuan untuk menu ini" v-on:click="showHelp()" type="button" class="w3-btn w3-tiny de-btn de-blue " style="margin-right:2px;"><i class="fa fa-question"></i> </button>
            </div>

            <div id="idHelp" class="w3-modal">
                <div id="help" class="w3-modal-content w3-animate-zoom w3-card-4 w3-theme-d3 w3-round-large" style="width:900px;position: absolute;">   
                    <header class="w3-container w3-theme-d3 drag w3-round-large" style="cursor:move;" id="helpheader">
                        <span @click="closeHelp" class="w3-display-topright w3-large w3-button w3-hover-red w3-round" style="background-color: inherit; cursor: pointer; margin: 5px 10px 0 0; width: 30px; height: 30px; padding: 1px;" >&times;</span>
                        <h6><i class="fa fa-question"></i> Help</h6>
                    </header>
                    <div class="w3-container w3-theme-l4 de-box" style="margin:0px 10px 10px 10px;">
                        <iframe v-if="help!=''" style="width: 100%;height: 400px;" :src="'https://www.youtube.com/embed/' + help" frameborder="0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
            </div>

            <!-- Advance Filter -->
            <div id="idfilter" class="w3-modal">
                <div id="filter" class="w3-modal-content w3-animate-zoom w3-card-4 w3-theme-d3 w3-round-large" style="width:600px;position: absolute;">
                    <header class="w3-container w3-theme-d3 drag w3-round-large" style="cursor:move;" id="filterheader"> 
                        <span @click="closeFilter" 
                        class="w3-display-topright w3-large w3-button w3-hover-red w3-round" style="background-color: inherit; cursor: pointer; margin: 5px 10px 0 0; width: 30px; height: 30px; padding: 1px;" >&times;</span>
                        <h6><i class="fa fa-filter"></i> Filter</h6>
                    </header>
                    <div class="w3-container w3-theme-l4 de-box" style="margin:0px 10px 0px 10px; padding: 5px!important;">
                        <div class="w3-theme-l1 w3-round" style="padding: 4px;">
                            <div class="w3-row">
                                <div class="w3-col l12 m12 s12">
                                    <select v-model="filters.operator" class="w3-select w3-small" style="width:100px;">
                                        <option value="AND">AND</option>
                                        <option value="OR">OR</option>
                                    </select>
                                    <button v-on:click="addFilter()" type="button" class="w3-small de-btn de-green" style="margin-left:5px;height: 35px;"><i class="fa fa-plus"></i> Tambahkan Kondisi</button>
                                </div>
                            </div>
                        </div>

                        <div class="w3-theme-l1 w3-round" style="padding: 5px!important; margin-top: 5px;">
                            <div class="w3-row" v-for="(item, index) in filters.findQuery" :key="index" style="margin-bottom: 2px;">
                                <div class="w3-col l12 m12 s12">
                                    <select v-on:change="setTypeQuery(item)" v-model="item.field" class="w3-select w3-small" style="width:150px;height: 34px;">
                                        <option  v-for="fl in setFilterField(fieldFind)" :key="fl.kunci" :value="fl.kunci">{{fl.name}}</option>
                                    </select>
                                    <span>&nbsp;</span>
                                    <select v-model="item.kondisi" class="w3-select w3-small" style="width:50px;margin-right:2px!important;height: 34px;">
                                        <option v-for="fl in filters.kondisi" :key="fl.key" :value="fl.key">{{fl.key}}</option>
                                    </select>
                                    <input v-if="item.type=='text'" type="text" class="w3-input w3-small" v-model="item.fieldValue" style="width:270px;display: inline-block!important; margin-right:2px;">

                                    <select v-if="item.type=='select'" v-model="item.fieldValue" class="w3-select w3-small" style="width:270px;display: inline-block!important; margin-right:2px;height: 34px;">
                                        <option  v-for="fl in item.valueFind" :key="fl.key" :value="fl.key">{{fl.label}}</option>
                                    </select>

                                    <div v-if="item.type=='date'" style="position: relative;width:270px;display: inline-block!important; margin-right:2px;">
                                        <input type="date" class="w3-input w3-small" placeholder="Tanggal" v-model="item.fieldValue">
                                    </div>

                                    <button v-on:click="removefilter(index)" type="button" class="w3-btn w3-small w3-yellow de-btn de-red" style="margin-left:2px;height: 34px;"><i class="fa fa-remove"></i></button>
                                </div>
                            </div>
                        </div> 

                    </div>
                    <footer class="w3-container">
                        <h6 class="w3-right">
                            <button v-on:click="prosesFilter()" type="button" class="w3-btn de-btn de-blue w3-small" style="margin-right:5px;"><i class="fa fa-search"></i> Filter</button>
                            <button @click="closeFilter" class="w3-btn w3-red w3-small de-btn de-red"><i class="fa fa-close"></i> Close</button>
                        </h6>
                    </footer>
                </div>
            </div>
            <!-- Advance Filter -->

        </div>
        <div style="position: absolute; top: 25px; z-index: 1; padding-left: 1px;" v-if="showMenu" class="w3-hide-large w3-hide-medium w3-animate-zoom">
            <ul style="padding: 2px; width: 40px;">
                <li v-if="statusAdd" @click="()=>{showAdd();hideMenu();}" class="w3-bar-item w3-btn de-btn de-blue w3-blue w3-large" style="margin-top: 2px; width: 43px;"><i class="fa fa-plus"></i></li>
                <li v-if="statusRefresh" @click="()=>{showRefresh();hideMenu();}" class="w3-bar-item w3-btn de-btn de-blue w3-green w3-large" style="margin-top: 2px; width: 43px;"><i class="fa fa-refresh"></i></li>
                <li v-if="statusExport" @click="()=>{generate();hideMenu();}" class="w3-bar-item w3-btn de-btn de-blue w3-theme-d3 w3-large" style="margin-top: 2px; width: 43px;"><i class="fa fa-file-excel-o"></i></li>
                <li v-if="statusPrint" @click="()=>{showPrint();hideMenu();}" class="w3-bar-item w3-btn de-btn de-blue w3-orange w3-large" style="margin-top: 2px; width: 43px;"><i class="fa fa-print"></i></li>
                <li v-if="statusFilterAdvance" @click="()=>{showFilterAdvance();hideMenu();}" class="w3-bar-item w3-btn de-btn de-blue w3-yellow w3-large" style="margin-top: 2px; width: 43px;"><i class="fa fa-filter"></i></li>
                <slot name="button-mobile" ></slot>
            </ul>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
export default {
    name:"DeToolbar",
    mounted(){
        this.dragElement(document.getElementById("help"));
        this.dragElement(document.getElementById("filter"));
    },
    components: {
        
    },
    data () {
        return {
            showMenu: false,
            filters : {
                operator:"AND", 
                kondisi :[
                    {key : "="},
                    {key : "like"},
                    {key : ">"},
                    {key : ">="},
                    {key : "<"},
                    {key : "<="},
                    {key : "!="}
                ],
                findQuery : [
                    {field : "" , kondisi:"like", fieldValue : "",type:"text", showdate : false}
                ]
            }
        };
    },
    props: {
        "fieldFind" : {
            type: Array,
            required: true
        },
        setFilter: {
            type: Function,
            required: true,
            default: () => { }
        },
        clickAdd: {
            type: Function,
            default: () => { }
        },
        clickFilter: {
            type: Function,
            default: () => { }
        },
        clickRefresh: {
            type: Function,
            default: () => { }
        },
        clickExport: {
            type: Function,
            default: () => { }
        },
        clickPrint: {
            type: Function,
            default: () => { }
        },
        clickHideMenu: {
            type: Function,
            default: () => { }
        },
        /**Untuk export xls*/
        // mime type [xls, csv], default: xls
        "type" : {
            type: String,
            default: "xls"
        },
        // Json to download
        "data":{
            type: Array,
            required: false
        },
        "fields":{
            type: Object,
            required: false
        },
        "exportFields":{
            type: Object,
            required: false
        },
        "title":{
            default: null
        },
        "footer":{
            default: null
        },
        "name":{
            type: String,
            default: "data.xls"
        },
        "meta":{
            type: Array,
            default: () => []
        },
        "help":{
            type : String,
            default : ""
        },
        statusAdd : {
            type : Boolean,
            default : true
        },
        statusRefresh : {
            type : Boolean,
            default : true
        },
        statusExport : {
            type : Boolean,
            default : true
        },
        statusPrint : {
            type : Boolean,
            default : true
        },
        statusFilter : {
            type : Boolean,
            default : true
        },
        statusFilterAdvance :{
            type : Boolean,
            default : false
        }
    },
    computed:{
        idName : function(){
            const now = new Date().getTime();
            return "export_"+now;
        },
        downloadFields: function(){
            if(this.fields != undefined)
                return this.fields;
            
            if(this.exportFields !== undefined)
                return this.exportFields;

            return "";
        }
    },
    // emits: {
    //     eventMenuAtas(){
    //         console.log("Emit");
    //         this.showMenu = !this.showMenu;
    //     }
    // },
    methods : {
        hideMenu(){
            this.showMenu = !this.showMenu;
        },
        showHelp(){
            document.getElementById('help').classList.remove("de-animate-close");
            document.getElementById("idHelp").style.display="block";
        },
        closeHelp(){
            document.getElementById('help').classList.add("de-animate-close");
            setTimeout(() => {
                document.getElementById('idHelp').style.display='none';
            }, 500);
        },
        setTypeQuery(item){
            let obj = this.findObj(this.fieldFind,"key",item.field);
            // console.log(obj,item.field,this.fieldFind);
            if(obj==undefined){
                obj = this.findObj(this.fieldFind,"fieldFind",item.field);
            }
            item.type = obj.type;
            if(obj.type=="select"){
                item["valueFind"] = obj.valueFind;
            }else{
                item["valueFind"] = [];
                item["showdate"] = false;
                item["fieldValue"] = "";
            }
            // console.log(item, obj);
        },
        showFilterAdvance(){
            document.getElementById('filter').classList.remove("de-animate-close");
            document.getElementById("idfilter").style.display="block";
        },
        closeFilter(){
            document.getElementById('filter').classList.add("de-animate-close");
            setTimeout(() => {
                document.getElementById('idfilter').style.display='none'
            }, 500);
        },
        addFilter(){
            let obj = this.setFilterField(this.fieldFind);
            this.filters.findQuery.push({field : obj[0]["kunci"] , kondisi:"like", fieldValue : "", type:obj[0]["type"]}); 
            // this.setTypeQuery(this.filters.findQuery[0])
        },
        prosesFilter(){
            // console.log(this.filters.findQuery, this.filters.operator);
            this.setFilter(this.filters.findQuery, this.filters.operator);
        },
        removefilter(idx){
            if(idx>0){
                this.filters.findQuery.splice(idx,1);
                // this.$delete(this.filters.findQuery,idx);
            }
        },
        showAdd (){
            this.clickAdd();
        },
        showRefresh (){
            this.clickRefresh();
        },
        showPrint (){
            this.clickPrint();
        },
        setFilterField(fl){
            let obj = [];
            fl.map(function (item) {
                if(item.filter==true){
                    if(item["fieldFind"]!=undefined){
                        if(item["fieldFind"]!=""){
                            item["kunci"] = item["fieldFind"];
                        }else{
                            item["kunci"] = item["key"];
                        }
                    }else{
                        item["kunci"] = item["key"];
                    }
                    obj.push(item);
                }
            });
            return obj;
        },
        /**Untuk excel*/
        generate() {
            if(!this.data.length){
                return;
            }
            let json = this.getProcessedJson(this.data, this.downloadFields);
            if(this.type == "csv"){
                return this.export(this.jsonToCSV(json), this.name, "application/csv");
            }
            return this.export(this.jsonToXLS(json), this.name, "application/vnd.ms-excel");
        },
        /*
        Use downloadjs to generate the download link
        */
        export (data, filename, mime) {
            let blob = this.base64ToBlob(data, mime);
            this.downloadFile(filename, blob);
            // download(blob, filename, mime)
        },
        /*
        jsonToXLS
        ---------------
        Transform json data into an xml document with MS Excel format, sadly
        this format show a prompt when open due to a default behavior
        on Microsoft office. It's recommended to use CSV format instead.
        */
        jsonToXLS (data) {
            let xlsTemp = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta name=ProgId content=Excel.Sheet> <meta name=Generator content="Microsoft Excel 11"><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>${table}</table></body></html>';
            let xlsData = "<thead><tr>";
            const colspan = Object.keys(data[0]).length;
            //Header
            if( this.title != null ){
                xlsData += this.parseExtraData(this.title, '<th colspan="'+colspan+'">${data}<th></tr><tr>');
            }
            //Fields
            for (let key in data[0]) {
                xlsData += "<th>" + key + "</th>";
            }
            xlsData += "</tr></thead>";
            xlsData += "<tbody>";
            //Data
            data.map(function (item) {
                xlsData += "<tbody><tr>";
                for (let key in item) {
                    xlsData += "<td>" + item[key] + "</td>";
                }
                xlsData += "</tr></tbody>";
            });
            //Footer
            if( this.footer != null ){
                xlsData += "<tfooter><tr>";
                xlsData += this.parseExtraData(this.footer, '<td colspan="'+colspan+'">${data}<td></tr><tr>');
                xlsData += "</tr></tfooter>";
            }
            return xlsTemp.replace("${table}", xlsData);
        },
        /*
        jsonToCSV
        ---------------
        Transform json data into an CSV file.
        */
        jsonToCSV (data) {
            let csvData = [];
            //Header
            if( this.title != null ){
                csvData.push(this.parseExtraData(this.title, "${data}\r\n"));
            }
            //Fields
            for (let key in data[0]) {
                csvData.push(key);
                csvData.push(",");
            }
            csvData.pop();
            csvData.push("\r\n");
            //Data
            data.map(function (item) {
                for (let key in item) {
                    let escapedCSV = item[key] + ""; // cast Numbers to string
                    if (escapedCSV.match(/[,"\n]/)) {
                        escapedCSV = '"' + escapedCSV.replace(/"/g, "\"\"") + '"';
                    }
                    csvData.push(escapedCSV); 
                    csvData.push(",");
                }
                csvData.pop();
                csvData.push("\r\n");
            });
            //Footer
            if( this.footer != null ){
                csvData.push(this.parseExtraData(this.footer, "${data}\r\n"));
            }
            return csvData.join("");
        },
        /*
        getProcessedJson
        ---------------
        Get only the data to export, if no fields are set return all the data
        */
        getProcessedJson: function(data, header){
            let keys = this.getKeys(data, header);
            let newData = [];
            let _self = this;
            data.map(function (item) {

                let newItem = {};
                for( let label in keys){
                    // var iii= item;
                    let property = keys[label];
                    newItem[label] = _self.getNestedData(property, item);
                }
                newData.push(newItem);
            });
            return newData;
        },
        getKeys: function(data, header){
            if( header ){
                return header;
            }
            let keys = {};
            for (let key in data[0]) {
                keys[key] = key;
            }
            return keys;
        },
        /*
        parseExtraData
        ---------------
        Parse title and footer attribute to the csv format
        */
        parseExtraData(extraData, format){
            let parseData = "";
            if( Array.isArray(extraData) ){
                for (let i = 0; i < extraData.length; i++) {
                    parseData += format.replace("${data}", extraData[i]);
                }
            }
            else{
                parseData += format.replace("${data}", extraData);
            }
            return parseData;
        },
        callItemCallback: function(field, itemValue) {
            if (typeof field === "object" && typeof field.callback === "function") {
                return field.callback(itemValue);
            }
            return itemValue;
        },
        getNestedData: function(key, item) { 
            const field = (typeof key === "object") ? key.field : key; 
  
            let valueFromNestedKey = null ;
            let keyNestedSplit = field.split(".") ;
  
            valueFromNestedKey = item[keyNestedSplit[0]] ;
            for (let j = 1; j < keyNestedSplit.length; j++) { 
                valueFromNestedKey = valueFromNestedKey[keyNestedSplit[j]] ;
            } 
  
            valueFromNestedKey = this.callItemCallback(key, valueFromNestedKey); 
  
            return valueFromNestedKey; 
        },
        base64ToBlob (data, mime) {
            let base64 = window.btoa(window.unescape(encodeURIComponent(data)));
            let bstr = atob(base64);
            let n = bstr.length;
            let u8arr = new Uint8ClampedArray(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
        }
    }
};
</script>