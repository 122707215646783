/* eslint-disable */
import { createRouter, createWebHashHistory } from 'vue-router'
// import Config from '@/conf/Config.js'

const routes = [
    {
        path: '/:catchAll(.*)',
        redirect: '/404'
    },
    {
        path: '/404',
        component: () => import("@/views/public/NoPage.vue"),
        name: "404",
        meta: {
            auth: false,
            title : "Not Found",
            icon: "fa fa-magic",
            closable: true
        }
    },
    // Untuk Pemohon
    {
        path: "/",
        name: "Home",
        component: () => import("@/views/pemohon/LoginPemohon.vue"),
        meta: {
            auth: false,
            title : 'Home'
        },
    },
    {
        path: "/registrasi",
        name: "Registrasi",
        component: () => import("@/views/pemohon/Register.vue"),
        meta: {
            auth: false,
            title : 'Registrasi'
        },
    },
    {
        path: "/calcenter",
        name: "Calcenter",
        component: () => import("@/views/pemohon/Calcenter.vue"),
        meta: {
            auth: false,
            title : 'Registrasi'
        },
    },
    {
        path: "/manual",
        name: "Manual",
        component: () => import("@/views/pemohon/Manual.vue"),
        meta: {
            auth: false,
            title : 'Registrasi'
        },
    },
    
    // Untuk admin
    {
        path: "/admin",
        name: "AdminLogin",
        component: () => import("@/views/public/AdminLogin.vue"),
        meta: {
            auth: false,
            title : 'AdminLogin'
        },
    },
    // Untuk Pemohon
    {
        path: "/",
        name: "MainPemohon",
        component: () => import('@/views/pemohon/panel/Main.vue'),
        meta: {
            auth: false,
            title : 'DashboardPemohon',
            tips: 'DashboardPemohon',
            icon: 'fa fa-home', 
            closable: false,
            tabClass: 'w3-theme-l1',
        },
        children: [
            {
                path: '*',
                component: () => import("@/views/public/NoPage.vue"),
                name: "NoPageAnak",
                meta: {
                    auth: false,
                    title : "Not Found",
                    icon: "fa fa-magic",
                    closable: true
                }
            },
            // Untuk Pemohon
            {
                path: "/DashboardPemohon",
                name: "DashboardPemohon",
                component: () => import("@/views/pemohon/panel/Dashboard.vue"),
                meta: {
                    auth: false,
                    title : 'DashboardPemohon',
                    tips: 'DashboardPemohon',
                    icon: 'fa fa-dashboard', 
                    closable: false,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/ProsesPengajuan",
                name: "ProsesPengajuan",
                component: () => import("@/views/pemohon/panel/ProsesPengajuan.vue"),
                meta: {
                    auth: false,
                    title : 'ProsesPengajuan',
                    tips: 'ProsesPengajuan',
                    icon: 'fa fa-dashboard', 
                    closable: false,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/Tracking",
                name: "Tracking",
                component: () => import("@/views/pemohon/panel/Tracking.vue"),
                meta: {
                    auth: false,
                    title : 'Tracking',
                    tips: 'Tracking',
                    icon: 'fa fa-dashboard', 
                    closable: false,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/Pesan",
                name: "Pesan",
                component: () => import("@/views/pemohon/panel/Pesan.vue"),
                meta: {
                    auth: false,
                    title : 'Pesan',
                    tips: 'Pesan',
                    icon: 'fa fa-dashboard', 
                    closable: false,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/Daftar",
                name: "Daftar",
                component: () => import("@/views/pemohon/panel/Daftar.vue"),
                meta: {
                    auth: false,
                    title : 'Daftar',
                    tips: 'Daftar',
                    icon: 'fa fa-user-plus', 
                    closable: false,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/ProfilePemohon",
                name: "ProfilePemohon",
                component: () => import("@/views/pemohon/panel/ProfilePemohon.vue"),
                meta: {
                    auth: false,
                    title : 'ProfilePemohon',
                    tips: 'ProfilePemohon',
                    icon: 'fa fa-dashboard', 
                    closable: false,
                    tabClass: 'w3-theme-l1',
                }
            },
        ]
    },
    // Untuk admin
    {
        path: "/",
        name: "Main",
        component: () => import('@/views/admin/Main.vue'),
        meta: {
            auth: false,
            title : 'Dashboard',
            tips: 'Dashboard',
            icon: 'fa fa-home', 
            closable: false,
            tabClass: 'w3-theme-l1',
        },
        children: [
            {
                path: '*',
                component: () => import("@/views/public/NoPage.vue"),
                name: "NoPageAnak",
                meta: {
                    auth: false,
                    title : "Not Found",
                    icon: "fa fa-magic",
                    closable: true
                }
            },
            // Untuk admin
            {
                path: "/Dashboard",
                name: "Dashboard",
                component: () => import("@/views/admin/Dashboard.vue"),
                meta: {
                    auth: true,
                    title : 'Dashboard',
                    tips: 'Dashboard',
                    icon: 'fa fa-dashboard', 
                    closable: false,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/Jabatan",
                name: "Jabatan",
                component: () => import("@/views/admin/master/Jabatan.vue"),
                meta: {
                    auth: true,
                    title : 'Jabatan',
                    tips: 'Jabatan',
                    icon: 'fa fa-users', 
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/Jeniskapal",
                name: "JenisKapal",
                component: () => import("@/views/admin/master/JenisKapal.vue"),
                meta: {
                    auth: true,
                    title : 'Jenis Kapal',
                    tips: 'Jenis Kapal',
                    icon: 'fa fa-dashboard', 
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/AlatTangkap",
                name: "AlatTangkap",
                component: () => import("@/views/admin/master/AlatTangkap.vue"),
                meta: {
                    auth: true,
                    title : 'Alat Tangkap',
                    tips: 'Alat Tangkap',
                    icon: 'fa fa-dashboard', 
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/JenisIkan",
                name: "JenisIkan",
                component: () => import("@/views/admin/master/JenisIkan.vue"),
                meta: {
                    auth: true,
                    title : 'Jenis Ikan',
                    tips: 'Jenis Ikan',
                    icon: 'fa fa-dashboard', 
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/TypeKapal",
                name: "TypeKapal",
                component: () => import("@/views/admin/master/TypeKapal.vue"),
                meta: {
                    auth: true,
                    title : 'Type Kapal',
                    tips: 'Type Kapal',
                    icon: 'fa fa-dashboard', 
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/List",
                name: "List",
                component: () => import("@/views/admin/master/List.vue"),
                meta: {
                    auth: true,
                    title : 'List',
                    tips: 'List',
                    icon: 'fa fa-dashboard', 
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/Dermaga",
                name: "Dermaga",
                component: () => import("@/views/admin/master/Dermaga.vue"),
                meta: {
                    auth: true,
                    title : 'Pelabuhan',
                    tips: 'Pelabuhan',
                    icon: 'fa fa-home', 
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/Kapal",
                name: "Kapal",
                component: () => import("@/views/admin/master/Kapal.vue"),
                meta: {
                    auth: true,
                    title : 'Master Kapal',
                    tips: 'Master Kapal',
                    icon: 'fa fa-home', 
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/JenisLayanan",
                name: "JenisLayanan",
                component: () => import("@/views/admin/master/JenisLayanan.vue"),
                meta: {
                    auth: true,
                    title : 'Jenis Layanan',
                    tips: 'Jenis Layanan',
                    icon: 'fa fa-list', 
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/Layanan",
                name: "Layanan",
                component: () => import("@/views/admin/master/Layanan.vue"),
                meta: {
                    auth: true,
                    title : 'Layanan',
                    tips: ' Layanan',
                    icon: 'fa fa-list', 
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/Tarif",
                name: "Tarif",
                component: () => import("@/views/admin/master/Tarif.vue"),
                meta: {
                    auth: true,
                    title : 'Tarif',
                    tips: ' Tarif',
                    icon: 'fa fa-list', 
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/Perusahaan",
                name: "PeRusahaan",
                component: () => import("@/views/admin/master/Perusahaan.vue"),
                meta: {
                    auth: true,
                    title : 'Perusahaan',
                    tips: ' Perusahaan',
                    icon: 'fa fa-list', 
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/JenisAset",
                name: "JenisAset",
                component: () => import("@/views/admin/master/JenisAset.vue"),
                meta: {
                    auth: true,
                    title : 'Jenis Aset',
                    tips: ' Jenis Aset',
                    icon: 'fa fa-list', 
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/Aset",
                name: "Aset",
                component: () => import("@/views/admin/master/Aset.vue"),
                meta: {
                    auth: true,
                    title : 'Aset',
                    tips: ' Aset',
                    icon: 'fa fa-list', 
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/Satuan",
                name: "Satuan",
                component: () => import("@/views/admin/master/Satuan.vue"),
                meta: {
                    auth: true,
                    title : 'Satuan',
                    tips: ' Satuan',
                    icon: 'fa fa-list', 
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/Sertifikasi",
                name: "SertiFikasi",
                component: () => import("@/views/admin/master/Sertifikasi.vue"),
                meta: {
                    auth: true,
                    title : 'Sertifikasi',
                    tips: ' Sertifikasi',
                    icon: 'fa fa-list', 
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            // transaksi
            {
                path: "/PengajuanIzin",
                name: "PengajuanIzin",
                component: () => import("@/views/admin/pelayanan/PengajuanIzin.vue"),
                meta: {
                    auth: true,
                    title : 'Pengajuan Izin',
                    tips: ' Pengajuan Izin',
                    icon: 'fa fa-list', 
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/Administratif",
                name: "AdminisTratif",
                component: () => import("@/views/admin/pelayanan/Administratif.vue"),
                meta: {
                    auth: true,
                    title : 'Administratif',
                    tips: ' Administratif',
                    icon: 'fa fa-list', 
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/Validasi",
                name: "ValiDasi",
                component: () => import("@/views/admin/pelayanan/Validasi.vue"),
                meta: {
                    auth: true,
                    title : 'Validasi',
                    tips: ' Validasi',
                    icon: 'fa fa-list', 
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/Ttd",
                name: "TtD",
                component: () => import("@/views/admin/pelayanan/Ttd.vue"),
                meta: {
                    auth: true,
                    title : 'TTD',
                    tips: ' TTD',
                    icon: 'fa fa-list', 
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/Selesai",
                name: "SeleSai",
                component: () => import("@/views/admin/pelayanan/Selesai.vue"),
                meta: {
                    auth: true,
                    title : 'Selesai',
                    tips: ' Selesai',
                    icon: 'fa fa-list', 
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/Keuangan",
                name: "KeUangan",
                component: () => import("@/views/admin/pelayanan/Keuangan.vue"),
                meta: {
                    auth: true,
                    title : 'Keuangan',
                    tips: ' Keuangan',
                    icon: 'fa fa-list', 
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            // Manage user
            {
                path: "/profile",
                name: "profile",
                component: () => import("@/views/admin/tools/Profile.vue"),
                meta: {
                    auth: true,
                    title : 'Profile',
                    tips: 'Profile',
                    icon: 'fa fa-user', 
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/GroupUser",
                name: "GroupUser",
                component: () => import("@/views/admin/tools/KelompokUser.vue"),
                meta: {
                    auth: true,
                    title : 'Group User',
                    tips: 'Group User',
                    icon: 'fa fa-users', 
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            },
            {
                path: "/ManageUsers",
                name: "ManageUsers",
                component: () => import("@/views/admin/tools/ManageUser.vue"),
                meta: {
                    auth: true,
                    title : 'Manage User',
                    tips: 'Manage User',
                    icon: 'fa fa-users', 
                    closable: true,
                    tabClass: 'w3-theme-l1',
                }
            }
        ]
    }
];

const router = createRouter({
    history: createWebHashHistory(), //createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    try {
        // console.log(to);
        if ( to.name == null){
            next("/login");
        }
        if (to.matched.some(record => record.meta.auth)) {
            vm.checkLogin(function(res){
                if (res.success == true) {
                    // console.log(to.fullPath);
                    if(to.fullPath=="/"){
                        if (localStorage.admin == "F"){
                            next("/DashboardPemohon");
                        }else{
                            next("/Dashboard");
                        }
                    }else{
                        next();
                    }
                }else{
                    if (localStorage.admin == "T"){
                        next("/login");
                    }else{
                        next("/");
                    }
                }
            }, function(err){
                next("/login");
            });
        } else {
            next();
            
        }
    } catch (error) {
        next("/login");
        // console.log('router error1', error);
    }
});

router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.name) {
        // Start the route progress bar.
        // NProgress.start();
        // this.showLoading("Loading ..");
        document.getElementById('txt-loading').innerHTML = "Loading ..";
        document.getElementById('idLoading').style.display='block';
    }
    next()
});


router.afterEach((to, from) => {
    // Complete the animation of the route progress bar.
    // NProgress.done()
    document.getElementById('idLoading').style.display='none';
});

export default router
