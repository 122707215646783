<template>
    <div v-if="show" style="position: relative;">
        <div class="w3-modal-content w3-animate-zoom w3-margin-top" style="max-width:100px;background-color: inherit!important;">
            <div class="w3-center">
                <div class="de-loader"></div>
                <div class="loader-text w3-text-black">{{msg}}</div>
            </div>            
            <img src="assets/images/logo.png" class="w3-image" style="width: 100px; position: absolute;top:0px; right: 0px;"/>
        </div>
    </div>
</template>
<script>
export default {
    name: "DeLoading",
    props: {
        show: {
            type: Boolean,
            default: false
        },
        msg: {
            type: String,
            default: "Loading ..."
        }
    }
};
</script>
<style>
.de-loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid blue;
    border-right: 16px solid green;
    border-bottom: 16px solid red;
    border-left: 16px solid pink;
    width: 100px;
    height: 100px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  /* Animasi ke dua */
  .de-loader-1 {
    width: 40px;
    height: 40px;
    background-color: #333;
  
    margin: 100px auto;
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
  }
  
  @-webkit-keyframes sk-rotateplane {
    0% { -webkit-transform: perspective(100px) }
    50% { -webkit-transform: perspective(100px) rotateY(180deg) }
    100% { -webkit-transform: perspective(100px) rotateY(180deg)  rotateX(180deg) }
  }
  
  @keyframes sk-rotateplane {
    0% { 
      transform: perspective(100px) rotateX(0deg) rotateY(0deg);
      -webkit-transform: perspective(100px) rotateX(0deg) rotateY(0deg) 
    } 50% { 
      transform: perspective(100px) rotateX(-180.1deg) rotateY(0deg);
      -webkit-transform: perspective(100px) rotateX(-180.1deg) rotateY(0deg) 
    } 100% { 
      transform: perspective(100px) rotateX(-180deg) rotateY(-179.9deg);
      -webkit-transform: perspective(100px) rotateX(-180deg) rotateY(-179.9deg);
    }
  }

  /* Animasi */
  .de-loader-x{
    width:100px;
    height:100px;
    display:flex;
    justify-content:center;
    align-items:center;
    margin:10px 20px;
  }
  @keyframes load-semua{
    50%{
      transform:rotatez(180deg);
      border-width:40px;
    }
    100%{
      transform:rotatez(360deg);
    }
  }
  .loader-semua::before{
    content:"";
    color:white;
    height:0px;
    width:0px;
    background:transparent;
    border-radius:50%;
    border:35px ridge red;
    border-color:indigo lime yellow orangered;
    animation:load-semua .5s infinite;
  }
  .loader-text{
    color: white;
    text-align: center;
    position: relative;
    top: 60%;
    font-size: 1em;
}
</style>